<template lang="">
    <div>
        <b-modal
            id="modal-sendEmail"
            size="lg"
            v-model="isOpen"
            centered
            hide-header
            cancel-variant="none"
            ok-title="Gửi"
            cancel-title="Quay lại"
            ok-variant="primary"
            :no-close-on-backdrop="true"
            @ok="sendEmail"
        >
            <b-card-header class="banned-background-color-primary">
                <div class="d-flex justify-content-between align-items-center block-header">
                    <p class="mb-0 title-modal color-primary">
                        Xác nhận Email
                    </p>
                    <feather-icon
                    icon="XIcon"
                    size="24"
                    class="cursor-pointer color-primary"
                    @click="hideModal"
                    />
                </div>
            </b-card-header>
            <b-card-body>
                <div class="inputEmail">
                    <span style="padding: 0 10px;width:10%;margin: auto;">To:</span>
                    <b-form-input
                        v-model="Email.To"
                        placeholder="To"
                    />
                </div>
                <div class="inputEmail">
                    <span style="padding: 0 10px;width:10%;margin: auto">Cc:</span>
                    <b-form-input
                        v-model="Email.Cc"
                        placeholder="Cc"
                    />
                </div>
                <div class="inputEmail">
                    <span style="padding: 0 10px;width:10%;margin: auto">Bcc:</span>
                    <b-form-input
                        v-model="Email.Bcc"
                        placeholder="Bcc"
                    />
                </div>
                <div class="inputEmail">
                    <span style="padding: 0 10px;width:10%;margin: auto">Subject:</span>
                    <b-form-input
                        v-model="Email.Subject"
                        placeholder="Subject"
                    />
                </div>
            </b-card-body>
        </b-modal>
    </div>
</template>
<script>
import { booking } from '@/api/booking'
import { commonServices } from "@/api/common-services";
export default {
    props: [
        'data',
        'bodyEmail',
        'type'
    ],
    data() {
        return {
            isOpen: false,
            GolfEmail: "booking@royalgolf.thanhcong.vn",
            GuestEmail: null,
            Email: {
                From: "booking@royalgolf.thanhcong.vn",
                To: null,
                Cc: null,
                Bcc: null,
                Body: null,
                Subject: null,
                BookingId: null,
                BookingDetailId: null,
                TransactionCode: "IBK01",
                EmailId: null,
            }
        }
    },
    created() {
        this.getEmail()
    },
    methods: {
        open() {
            this.isOpen = true
            if(this.type == 'BOOKED_GROUP') {
                this.Email.To = null
                this.Email.Body = this.bodyEmail
                this.Email.BookingId = this.data.BookingId
                this.Email.Subject = 'Xác nhận thông tin booking'
            }
            if(this.type == 'BOOKED') {
                this.Email.Body = this.bodyEmail
                this.Email.BookingId = this.data.BookingId
                this.Email.To = this.data.Email
                this.Email.BookingDetailId = this.data.Id
                this.Email.Subject = 'Xác nhận thông tin booking'
            }
        },
        async getEmail() {
            const body = {
                KeyGroup: "EMAIL_ACC_CONFIRMATION",
            };
            await commonServices.getCommon(body).then(res => {
                res.Data.forEach(x => {
                    if(x.KeyCode == "EMAIL_CONFIRMATION") {
                        this.Email.From = x.KeyValue
                    }
                })
            })
        },
        hideModal() {
            this.$bvModal.hide('modal-sendEmail')
        },
        sendEmail() {
            this.call_api_IEM01().then(() => {
                this.call_api_IME02()
            })
        },
        async call_api_IEM01() {
            const body = {
                Email: this.Email
            }
            await booking.api_IEM01(body).then(res => {
                this.EmailId =  res.Data.EmailId
            })
        },
        async call_api_IME02() {
            const body = {
                EmailId: this.EmailId
            }
            await booking.api_IEM02(body).then(res => {
                this.showResToast(res)
            })
        },
    }
}
</script>
<style lang="scss" scoped>
#modal-sendEmail {
  .block-header {
    .title-modal {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
  }

  .color-primary {
    color: #114A9F;
  }
  .content-body-modal {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }


  .block {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      padding: 0;
    }

    p {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }
  .inputEmail{
    display: flex;
    margin-bottom: 10px;
  }
}
</style>